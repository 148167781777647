import {
  Box,
  BoxProps,
  Button,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { AppearanceIcon, GridIcon, ListIcon } from '../../icons';
import { getPolyglot } from '../../../../../i18n';

interface AppearanceSelectProps extends BoxProps {
  gridDisplay: boolean;
  onChangeAppearance: (displayGrid: boolean) => void;
}
const AppearanceSelect = (props: AppearanceSelectProps) => {
  const { gridDisplay, onChangeAppearance, ...rest } = props;
  const theme = useTheme();
  const changeAppearance = (displayGrid: boolean) => {
    onChangeAppearance(displayGrid);
  };
  const polyglot = getPolyglot();

  return (
    <Box {...rest}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        gap="1rem"
        padding={'0 0.5rem'}
      >
        <Button
          variant="text"
          disableRipple={true}
          disableFocusRipple={true}
          sx={{
            color: theme.palette.common.black,
            cursor: 'default',
            '&:hover': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
          startIcon={<AppearanceIcon fontSize="1rem" />}
        >
          <Typography color="common.black" fontSize={'1rem'}>
            {polyglot.t('freshwatch.dashboard.appareance')}
          </Typography>
        </Button>
      </Box>
      <Box display={'flex'}>
        <IconButton
          size="large"
          sx={{
            backgroundColor: `${
              gridDisplay
                ? theme.palette.secondary.main
                : theme.palette.primary.contrastText
            }`,
            borderRadius: '0.7rem',
            padding: '1rem',
            margin: '0.5rem',
            boxShadow: !gridDisplay ? 2 : 0,
            width: '3.5rem',
            '&:hover': {
              backgroundColor: !gridDisplay
                ? theme.palette.secondary.light
                : theme.palette.secondary.main,
            },
          }}
          onClick={() => changeAppearance(true)}
        >
          <GridIcon color={theme.palette.primary.main} />
        </IconButton>
        <IconButton
          sx={{
            backgroundColor: `${
              !gridDisplay
                ? theme.palette.secondary.main
                : theme.palette.primary.contrastText
            }`,
            borderRadius: '0.7rem',
            padding: '1rem',
            margin: '0.5rem',
            width: '3.5rem',
            boxShadow: gridDisplay ? 2 : 0,
            '&:hover': {
              backgroundColor: gridDisplay
                ? theme.palette.secondary.light
                : theme.palette.secondary.main,
            },
          }}
          size="large"
          onClick={() => changeAppearance(false)}
        >
          <ListIcon color={theme.palette.primary.main} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default AppearanceSelect;
