import { Box, IconButton, Skeleton, Typography, useTheme } from '@mui/material';
import React from 'react';
import { MercIcon, PinIcon, StoreIcon } from '../../../../theme/icons';
import OverviewCard, {
  OverviewCardItem,
} from '../../../../theme/components/OverviewCard/OverviewCard';
import { getPolyglot } from '../../../../../../i18n';
import { Merchandiser } from '../../../../redux/composableDevice/model/Merchandiser.model';
import { AlarmIcon } from '../../../../../../theme/icons';

interface OverviewPanelProps {
  totalRegions: number;
  totalStores: number;
  merchandisers: Merchandiser[];
  isLoading: boolean;
  countAlarmsActive: number;
  isLoadingAlarmsActive: boolean;
}
const OverviewPanel = (props: OverviewPanelProps) => {
  const {
    totalRegions,
    totalStores,
    merchandisers,
    isLoading,
    countAlarmsActive,
    isLoadingAlarmsActive,
  } = props;
  const theme = useTheme();

  const polyglot = getPolyglot();

  return (
    <OverviewCard
      sx={{
        boxShadow: 2,
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0.5rem 0',
      }}
    >
      <OverviewCardItem sx={{ gap: 2 }}>
        <IconButton
          disableRipple={true}
          sx={{ p: 0, margin: '1rem', cursor: 'default', width: '4.5rem' }}
        >
          <PinIcon
            color={`${theme.palette.primary.main}`}
            height={'4.5rem'}
            width={'100%'}
          />
        </IconButton>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography variant="h4" color="common.black">
            {polyglot.t('freshwatch.dashboard.regions')}
          </Typography>
          <Typography
            variant="h6"
            fontSize={'5rem'}
            lineHeight={'5rem'}
            color="common.black"
          >
            {isLoading ? <Skeleton width={80} /> : totalRegions}
          </Typography>
        </Box>
      </OverviewCardItem>
      <OverviewCardItem sx={{ gap: 2 }}>
        <IconButton
          disableRipple={true}
          sx={{ p: 0, margin: '1rem', cursor: 'default', width: '4.5rem' }}
        >
          <StoreIcon
            color={`${theme.palette.primary.main}`}
            height={'4.5rem'}
            width={'100%'}
          />
        </IconButton>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography variant="h4" color="common.black">
            {polyglot.t('freshwatch.dashboard.stores')}
          </Typography>
          <Typography
            variant="h6"
            fontSize={'5rem'}
            lineHeight={'5rem'}
            color="common.black"
          >
            {isLoading ? <Skeleton width={80} /> : totalStores}
          </Typography>
        </Box>
      </OverviewCardItem>
      <OverviewCardItem sx={{ gap: 2 }}>
        <IconButton
          disableRipple={true}
          sx={{ p: 0, margin: '1rem', cursor: 'default', width: '4.5rem' }}
        >
          <MercIcon
            color={`${theme.palette.primary.main}`}
            height={'4.5rem'}
            width={'100%'}
          />
        </IconButton>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography variant="h4" color="common.black">
            {polyglot.t('freshwatch.dashboard.merchandisers')}
          </Typography>
          <Typography
            variant="h6"
            fontSize={'5rem'}
            lineHeight={'5rem'}
            color="common.black"
          >
            {isLoading ? <Skeleton width={80} /> : merchandisers.length}
          </Typography>
        </Box>
      </OverviewCardItem>
      <OverviewCardItem sx={{ gap: 2 }}>
        <IconButton
          disableRipple={true}
          sx={{ p: 0, margin: '1rem', cursor: 'default', width: '4.5rem' }}
        >
          <AlarmIcon
            color={`${theme.palette.error.main}`}
            height={'4.5rem'}
            width={'100%'}
          />
        </IconButton>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography variant="h4" color="common.black">
            {polyglot.t('freshwatch.dashboard.alarms')}
          </Typography>
          <Typography
            variant="h6"
            fontSize={'5rem'}
            lineHeight={'5rem'}
            color="common.black"
          >
            {isLoadingAlarmsActive ? (
              <Skeleton width={80} />
            ) : (
              countAlarmsActive
            )}
          </Typography>
        </Box>
      </OverviewCardItem>
    </OverviewCard>
  );
};

export default OverviewPanel;
