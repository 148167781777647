import React from 'react';
import { Group } from '../../../../../../redux/groups/api/group.model';
import { getAllChildrenGroups } from '../../../../../../redux/devices/selectors';
import { Merchandiser } from '../../../../redux/composableDevice/model/Merchandiser.model';
import { IncidentAPIResponseMVP } from '../../../../../../redux/incident/api/Incident.model';
import {
  createRegionDetails,
  getAllDeviceIds,
  SCC_TYPE,
  sortRegionDetails,
} from '../../../../redux/composableDevice/model/SCCGroup.model';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { getPolyglot } from '../../../../../../i18n';
import StoreOverviewWrapper from '../../../../theme/components/Store/StoreOverviewWrapper/StoreOverviewWrapper';
import { MapWithDevices } from './MapRegion';
import MerchandiserOverview from '../PanelSection/MerchandiserOverview/MerchandiserOverview';
import AlarmOverview from '../PanelSection/AlarmOverview/AlarmOverview';
import {
  filterBySCCType,
  RegionDetails,
} from '../../../../redux/composableDevice/model/SCCGroup.model';

const Region = ({
  region,
  merchandisers,
  stores,
  alarms,
  gridDisplay = false,
}: {
  region: Group;
  merchandisers: Merchandiser[];
  stores: Group[];
  alarms: IncidentAPIResponseMVP[];
  gridDisplay: boolean;
}) => {
  const regionChildren = getAllChildrenGroups([region], []);
  const cities = regionChildren.filter(filterBySCCType(SCC_TYPE.CITY));
  const regionInformation = new Map<string, RegionDetails>();

  const polyglot = getPolyglot();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  cities.forEach((city) => {
    const regionDetails = createRegionDetails(
      city,
      stores,
      merchandisers,
      alarms
    );

    regionInformation.set(regionDetails.city.id, regionDetails);
  });

  const allDevicesIds = getAllDeviceIds(regionInformation);

  const noCities = (
    <Typography>
      {polyglot.t('device.search.message_no_results_found')}
    </Typography>
  );

  if (!regionInformation.size) {
    return noCities;
  }

  const sortedCities = new Map(
    [...regionInformation.values()]
      .sort(sortRegionDetails)
      .map((city) => [city.city.id, city])
  );

  let gridTemplateAreas = '';

  if (gridDisplay || isMobile) {
    gridTemplateAreas = `"location location"
    "map map"
    "merchandisers merchandisers"
    "alarms alarms"`;
  } else {
    gridTemplateAreas = `"location merchandisers"
    "map merchandisers"
    "alarms merchandisers"`;
  }

  return (
    <Box
      display={'grid'}
      gridTemplateAreas={gridTemplateAreas}
      gridTemplateColumns={'repeat(2, minmax(50%, auto))'}
      paddingBottom={'2rem'}
    >
      {/* List of Cities with the number of merchandisers */}
      <Box gridArea={'location'}>
        <StoreOverviewWrapper
          gridDisplay={gridDisplay}
          storeWithMerchandisers={sortedCities}
        />
      </Box>
      {/* Map Overview with the merchandisers in the city */}
      <Box
        gridArea={'map'}
        sx={{ height: '30vh', overflow: 'hidden', margin: '1rem 0' }}
      >
        {<MapWithDevices key={`map_${region.id}`} devicesIds={allDevicesIds} />}
      </Box>
      {/* Merchandisers Overview */}
      <Box gridArea={'merchandisers'}>
        <MerchandiserOverview
          displayGrid={gridDisplay}
          cityInformation={sortedCities}
        />
      </Box>
      {/* Alarms */}
      <Box gridArea={'alarms'}>
        <AlarmOverview
          gridDisplay={gridDisplay}
          alarmsActive={alarms}
          cities={sortedCities}
        />
      </Box>
    </Box>
  );
};

export default Region;
