import React, { useState } from 'react';
import OverviewPanel from './Panels/OverviewPanel';
import useAllMerchandisers from '../../../redux/composableDevice/hook/useAllMerchandisers';
import { useAppSelector } from '../../../../../redux/store.model';
import { getAllGroups } from '../../../../../redux/devices/selectors';
import { AccordionSummaryLight } from '../../../../../theme/components';
import {
  Accordion,
  accordionSummaryClasses,
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Region from './Region/Region';
import { ArrowDownIcon, PinIcon } from '../../../theme/icons';
import {
  filterBySCCType,
  getAlarmsInRegion,
  SCC_TYPE,
} from '../../../redux/composableDevice/model/SCCGroup.model';
import { AlarmState } from '../../../../../redux/incident/api/Incident.model';
import IncidentAPI from '../../../../../redux/incident/api/IncidentAPI';
import { getMyWorkspaceSelector } from '../../../../../redux/stoerkID/selectors/StoerkId.selectors';
import moment from 'moment';
import AppearanceSelect from '../../../theme/components/AppearanceSelect/AppearanceSelect';
import FilterWrapper from './Panels/FilterWrapper';

const RegionalManagerDashboard = () => {
  const { merchandisers, isLoading } = useAllMerchandisers();

  const workspace = useAppSelector(getMyWorkspaceSelector);
  const [gridDisplay, setGridDisplay] = useState(true);

  const theme = useTheme();

  const groups = useAppSelector((state) => getAllGroups(state));

  const regions = groups.filter(filterBySCCType(SCC_TYPE.REGION));
  const stores = groups.filter(filterBySCCType(SCC_TYPE.STORE));

  const [filterState, setFilterState] = useState({
    regions: regions,
    stores: stores,
    merchandisers: merchandisers,
    timePeriod: {
      before: new Date().getTime(),
      after: moment().subtract(1, 'week').valueOf(),
    },
  });

  const {
    data: { data: incidentsData = [], count: alarmsCount } = {
      data: [],
      count: 0,
    },
    error: errorIncidents,
    isLoading: isLoadingIncidents,
    isFetching: isFetchingIncidents,
  } = IncidentAPI.useQueryMyIncidentByWorkspaceIdQuery(
    {
      workspaceId: workspace?.id,
      params: {
        state: AlarmState.NEW,
        page_size: 100,
        offset: 0,
        created_after: filterState.timePeriod.after,
        created_before: filterState.timePeriod.before,
      },
    },
    {
      skip: !workspace?.id,
    }
  );

  const totalAlarms = incidentsData.filter((incident) =>
    filterState.merchandisers.find(
      (merch) => merch.deviceId === btoa(incident.deviceId)
    )
  );
  const alarmsByRegion = new Map(
    regions.map((region) => [region.id, getAlarmsInRegion(totalAlarms, region)])
  );

  const largerScreens = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <Grid sx={{ backgroundColor: 'common.white' }}>
        <FilterWrapper
          regions={regions}
          stores={stores}
          merchandisers={merchandisers}
          filterChange={(filters) => setFilterState(filters)}
        />
      </Grid>
      <Grid
        container
        sx={{
          backgroundColor: 'neutral.light',
          padding: '1em',
          flexWrap: largerScreens ? 'nowrap' : 'wrap',
        }}
      >
        <Grid item flexGrow={4}>
          <OverviewPanel
            totalRegions={filterState.regions.length}
            totalStores={filterState.stores.length}
            countAlarmsActive={totalAlarms.length ?? 0}
            isLoading={isLoading}
            merchandisers={filterState.merchandisers}
            isLoadingAlarmsActive={isLoadingIncidents || isFetchingIncidents}
          />
        </Grid>
        <Grid item flexGrow={1}>
          <AppearanceSelect
            sx={{ padding: '0 1rem' }}
            gridDisplay={gridDisplay}
            onChangeAppearance={(appearance) => setGridDisplay(appearance)}
          />
        </Grid>
      </Grid>
      <Box p={'0 1rem 1rem 1rem'}>
        <Grid item xs={12} sm={12}>
          {filterState.regions.length > 0
            ? filterState.regions.map((region) => (
                <Accordion
                  key={region.id}
                  sx={{
                    background: 'transparent',
                    border: 'none',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    padding: '0',
                  }}
                  defaultExpanded={true}
                >
                  <AccordionSummaryLight
                    id="regions-title"
                    aria-controls="accordion-regions-title"
                    expandIcon={
                      <IconButton>
                        <ArrowDownIcon
                          color={theme.palette.common.black}
                          height={'0.8rem'}
                        />
                      </IconButton>
                    }
                    sx={{
                      [`&.${accordionSummaryClasses.root}`]: {
                        padding: '0',
                        justifyContent: 'flex-start',
                      },
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      display={'flex'}
                      alignItems={'center'}
                      gap="1rem"
                      padding={'0 1rem'}
                    >
                      <PinIcon
                        fontSize="1.5rem"
                        width={'1.5rem'}
                        color={theme.palette.common.black}
                      />
                      <Typography color="common.black" fontWeight={'600'}>
                        {region.name}
                      </Typography>
                    </Grid>
                  </AccordionSummaryLight>
                  <Region
                    region={region}
                    merchandisers={filterState.merchandisers}
                    stores={filterState.stores}
                    alarms={alarmsByRegion.get(region.id) ?? []}
                    gridDisplay={gridDisplay}
                  />
                </Accordion>
              ))
            : null}
        </Grid>
      </Box>
    </>
  );
};

export default RegionalManagerDashboard;
