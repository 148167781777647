import { Box, CircularProgress, Container, Grid } from '@mui/material';
import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useGroupById } from '../../../../redux/groups/hooks/useGroupById';
import {
  getPolyglot,
  PolyglotComponentProps,
  withPolyglot,
} from '../../../../i18n';
import { ButtonBack, ListItem } from '../../../../theme/components';
import { Group } from '../../../../redux/groups/api/group.model';
import GroupModel from '../../../../redux/groups/api/GroupModel';
import { DeviceGroupIcon } from '../../../../theme/icons';
import { warmUpGroups } from '../../../../redux/groups/actions/thunks';
import { getGroups } from '../../../../redux/devices/selectors';
import { RootState } from '../../../../redux/store.model';
import { isGroupsFirstLoad } from '../../../../redux/groups/selectors';
import { RouterComponentProps, withRouter } from '../../../../util/route-dom';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../handlingErrors';
import { connect, ConnectedProps } from 'react-redux';

const GroupCardDashboard = ({
  group,
  isLoading,
}: {
  group: Group;
  isLoading: boolean;
}) => {
  return (
    <ListItem
      key={group.id}
      id={group.id}
      name={group?.name}
      loading={isLoading}
      iconURL={group?.iconURL}
      subtitle={GroupModel.getGroupSubtitles(group)}
      iconNode={!group?.iconURL && <DeviceGroupIcon />}
      className={GroupModel.getClassesNamesFromDevice(group)}
      ItemContainerProps={{
        sx: { px: 3, backgroundColor: 'background.container' },
      }}
    />
  );
};

const GroupContainer = ({ groupIdProp }: { groupIdProp: string }) => {
  const [group, isLoading] = useGroupById(groupIdProp);

  if (!group) return null;

  return <GroupCardDashboard group={group} isLoading={isLoading} />;
};

interface DashboardLayoutProps
  extends ConnectedComponentProps,
    PolyglotComponentProps,
    HandlingErrorWrappedProps,
    RouterComponentProps<{ groupId?: string | undefined }> {
  isGroupFirstLoad: boolean;
  groups: Group[];
}
export function DashboardLayout(props: DashboardLayoutProps) {
  const params = useParams();
  const polyglot = getPolyglot();
  const navigate = useNavigate();
  const isFirstLoad = props.isGroupsFirstLoad;

  const navigation = {
    content: polyglot.t('freshwatch.dashboard.regional_dashboard'),
    onClick: () => navigate('/dashboard'),
  };

  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        height: '100%',
        backgroundColor: 'background.container',
      }}
    >
      {params.groupId && (
        <>
          <Box
            sx={{
              px: 3,
              backgroundColor: 'background.container',
            }}
          >
            <ButtonBack onClick={navigation.onClick}>
              {navigation.content}
            </ButtonBack>
          </Box>
          <GroupContainer groupIdProp={params.groupId} />
        </>
      )}
      <Box
        py={1}
        sx={{
          backgroundColor: 'background.container',
        }}
      >
        {isFirstLoad ? (
          <Grid
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              p: '4rem',
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Outlet />
        )}
      </Box>
    </Container>
  );
}

const mapStateToProps = (state: RootState) => ({
  groups: getGroups(state),
  isGroupsFirstLoad: isGroupsFirstLoad(state),
});
const connector = connect(mapStateToProps, { warmUpGroups });
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withHandlingErrors(
  withPolyglot(withRouter(connector(DashboardLayout)))
);
