import React from 'react';
import FilterPanel from './FilterPanel';
import moment from 'moment';
import { Group } from '../../../../../../redux/groups/api/group.model';
import { Merchandiser } from '../../../../redux/composableDevice/model/Merchandiser.model';
import { getPolyglot } from '../../../../../../i18n';

interface FilterWrapperProps {
  regions: Group[];
  stores: Group[];
  merchandisers: Merchandiser[];
  filterChange: (filters: {
    regions: Group[];
    stores: Group[];
    merchandisers: Merchandiser[];
    timePeriod: { before: number; after: number };
  }) => void;
}
export const FilterWrapper = (props: FilterWrapperProps) => {
  const { regions, stores, merchandisers, filterChange } = props;
  const polyglot = getPolyglot();
  const timePeriods = [
    polyglot.t('freshwatch.dashboard.last_week'),
    polyglot.t('freshwatch.dashboard.last_month'),
    polyglot.t('freshwatch.dashboard.last_3_months'),
    polyglot.t('freshwatch.dashboard.last_6_months'),
    polyglot.t('freshwatch.dashboard.last_year'),
  ];
  const getTimePeriod = (timePeriod: string) => {
    switch (timePeriod) {
      case timePeriods[0]:
        return {
          before: new Date().getTime(),
          after: moment().subtract(1, 'week').valueOf(),
        };
      case timePeriods[1]:
        return {
          before: new Date().getTime(),
          after: moment().subtract(1, 'month').valueOf(),
        };
      case timePeriods[2]:
        return {
          before: new Date().getTime(),
          after: moment().subtract(3, 'month').valueOf(),
        };
      case timePeriods[3]:
        return {
          before: new Date().getTime(),
          after: moment().subtract(6, 'month').valueOf(),
        };
      case timePeriods[4]:
        return {
          before: new Date().getTime(),
          after: moment().subtract(1, 'year').valueOf(),
        };
      default:
        return {
          before: new Date().getTime(),
          after: moment().subtract(1, 'week').valueOf(),
        };
    }
  };

  return (
    <FilterPanel
      regions={regions}
      stores={stores}
      merchandisers={merchandisers}
      timePeriods={timePeriods}
      onFilterChange={(value) => {
        filterChange({
          stores: value.stores,
          regions: value.regions,
          merchandisers: value.merchandisers,
          timePeriod: getTimePeriod(value.timePeriod),
        });
      }}
    />
  );
};

export default FilterWrapper;
