import React from 'react';
import {
  Stack,
  Typography,
  TextField,
  CircularProgress,
  Box,
} from '@mui/material';
import { ErrorText } from '../../../theme/components/Forms';
import { useShowErrorMessage, useShowMessage } from '../../../util/hooks';
import { useAppDispatch, useAppSelector } from '../../../redux/store.model';
import { getPolyglot } from '../../../i18n';
import { Group, GroupCreateAPI } from '../../../redux/groups/api/group.model';
import { useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useDevices } from '../../../redux/devices/hooks/useDevices';
import { ST_COMPOSABLE_CONSUMPTION_DATA_KEY } from '../../../redux/composableDevice/ComposableDevice.model';
import {
  loadGroupsDevices,
  putGroup,
} from '../../../redux/groups/actions/thunks';

interface ConsumptionFormProps {
  group?: Group;
}

export default function ConsumptionForm({ group }: ConsumptionFormProps) {
  const polyglot = getPolyglot();
  const dispatch = useAppDispatch();

  const showError = useShowErrorMessage();
  const showSuccess = useShowMessage();

  const [devices, isLoadingDevices] = useDevices(group?.devices ?? []);

  const initialValues: Record<string, number> = group?.attributes[
    ST_COMPOSABLE_CONSUMPTION_DATA_KEY
  ]
    ? JSON.parse(
        group?.attributes[ST_COMPOSABLE_CONSUMPTION_DATA_KEY] as string
      )
    : {};

  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: async (values, helpers) => {
      try {
        const jsonConsumptionData = JSON.stringify(values);

        const groupdata: GroupCreateAPI = {
          name: group?.name ?? '',
          attributes: {
            [ST_COMPOSABLE_CONSUMPTION_DATA_KEY]: jsonConsumptionData,
            contacts: '',
          },
        };
        await dispatch(putGroup(group?.id ?? '', groupdata));
        await dispatch(loadGroupsDevices());
        showSuccess(polyglot.t('composable_device.save_successful_message'));
      } catch (error: any) {
        showError(polyglot.t('composable_device.failed_to_update'));
      }
    },
  });

  return (
    <Stack spacing={2}>
      {isLoadingDevices ? (
        <Box display={'flex'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant="h2">
            {polyglot.t('composable_device.dashboard_consumption_setting')}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              {devices.map((item) => (
                <TextField
                  key={item.uuid}
                  name={item.uuid}
                  type="number"
                  label={item.name?.concat(
                    ` ${polyglot.t(
                      'composable_device.dashboard_consumption_setting'
                    )}`
                  )}
                  onChange={formik.handleChange}
                  value={formik.values[item.uuid]}
                  defaultValue={formik.initialValues[item.uuid]}
                  required
                  error={
                    formik.touched[item.uuid] &&
                    Boolean(formik.errors[item.uuid])
                  }
                  helperText={
                    formik.touched[item.uuid] &&
                    formik.errors[item.uuid] && (
                      <ErrorText>
                        {polyglot.t(formik.errors[item.uuid] ?? '')}
                      </ErrorText>
                    )
                  }
                />
              ))}
              <LoadingButton
                color="primary"
                variant="contained"
                size="medium"
                type="submit"
                loading={formik.isSubmitting}
                onClick={formik.submitForm}
                startIcon={<SaveIcon />}
              >
                {polyglot.t('button.save')}
              </LoadingButton>
            </Stack>
          </form>
        </>
      )}
    </Stack>
  );
}
